<template>
    <div class="pt-8 pb-16 bg-white border-t sm:py-24">
        <div class="grid px-6 mx-auto max-w-7xl gap-x-8 gap-y-20 lg:px-8 xl:grid-cols-3">
          <div class="max-w-2xl">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet our team</h2>
            <p class="mt-6 text-lg leading-8 text-gray-600">Here is our dedicated, innovation,  visionary and high excellence team members </p>
            <a :href="href + 'Am interested let us work'" class="flex justify-start py-4">
              <button class="px-6 py-3 font-bold text-white rounded bg-primary">Hire Us Now</button>
            </a>
          </div>
          <ul role="list" class="space-y-16 md:grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2 md:space-y-0">
            <li class="flex items-center justify-center col-span-2">
              <div class="flex items-center gap-x-6">
                <img class="object-cover object-right-top w-40 h-40 scale-110 rounded-full" src="../assets/images/shadsilconi.jpeg" alt="">
                <div>
                  <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Shadrack S Mbwagha</h3>
                  <p class="text-sm font-semibold leading-6 text-primary">Co-Founder / CEO</p>
                  <p class="text-sm font-semibold leading-6 text-primary">Programmer & developer</p>
                </div>
              </div>
            </li>
            <li>
              <div class="flex items-center gap-x-6">
                <img class="w-16 h-16 rounded-full" src="../assets/images/man.svg" alt="">
                <div>
                  <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Praise Mhema</h3>
                  <p class="text-sm font-semibold leading-6 text-primary">Frontend Developer</p>
                </div>
              </div>
            </li>
            <li>
              <div class="flex items-center gap-x-6">
                <img class="w-16 h-16 rounded-full" src="../assets/images/man.svg" alt="">
                <div>
                  <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">?</h3>
                  <p class="text-sm font-semibold leading-6 text-primary">System analysts and design</p>
                </div>
              </div>
            </li>
            <li>
              <div class="flex items-center gap-x-6">
                <img class="w-16 h-16 rounded-full" src="../assets/images/man.svg" alt="">
                <div>
                  <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Magoko Burure</h3>
                  <p class="text-sm font-semibold leading-6 text-primary">Database design and implementations</p>
                </div>
              </div>
            </li>
            <li>
              <div class="flex items-center gap-x-6">
              <img class="w-16 h-16 rounded-full" src="../assets/images/man.svg" alt="">                
              <div>
                  <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Sermon</h3>
                  <p class="text-sm font-semibold leading-6 text-primary">UI/UX designer</p>
                </div>
              </div>
            </li>
            <li>
              <div class="flex items-center gap-x-6">
                <img class="w-16 h-16 rounded-full" src="../assets/images/man.svg" alt="">
                <div>
                  <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Fazal Bernard</h3>
                  <p class="text-sm font-semibold leading-6 text-primary">Mobile App developer</p>
                </div>
              </div>
            </li>
            <li>
              <div class="flex items-center gap-x-6">
                <img class="w-16 h-16 rounded-full" src="../assets/images/man.svg" alt="">
                <div>
                  <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">?</h3>
                  <p class="text-sm font-semibold leading-6 text-primary">Sales and marketing</p>
                </div>
              </div>
            </li>
            <li>
              <div class="flex items-center gap-x-6">
                <img class="w-16 h-16 rounded-full" src="../assets/images/man.svg" alt="">
                <div>
                  <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Godson</h3>
                  <p class="text-sm font-semibold leading-6 text-primary">Shipping and delivery</p>
                </div>
              </div>
            </li>
            
             <li>
              
            </li>
            <!-- More people... -->
          </ul>
          <div class="flex items-center gap-x-6">
                <!-- <img class="w-16 h-16 rounded-full" src="../assets/images/man.svg" alt=""> -->
            <div>Sponsered By ;</div>
              <div>
                <h3 class="text-base font-semibold leading-7 tracking-tight text-blue-600">Mbungula Company Limited</h3>
                <p class="text-sm font-semibold leading-6 text-primary"></p>
            </div>
           </div>
        </div>
      </div>
      
</template>

<script>
export default {
  data(){
    return {
       href: "https://wa.me/255767488809?text=",
    }
  }
}
</script>

<style>

</style>
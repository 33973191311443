<template>
    <!-- shorten section -->
    <section id="shorten" class="relative bg-gray-100">
        <!-- shorten container -->
        <div class="max-w-4xl p-6 mx-auto space-y-6">
            <!-- Form -->
            <form action="" id="link-form"
                class="relative flex flex-col w-full p-10 -mt-20 space-y-4 bg-gray-200 rounded-lg md:flex-row md:space-y-0 md:space-x-3">
                <input type="text" class="flex-1 p-3 border-2 rounded-lg focus:outline-none placeholder:text-xs"
                    placeholder="enquiry any tech services/product now " />
                
                <button class="px-10 py-3 text-white uppercase rounded-lg bg-cyan focus:outline-none hover:bg-cyanLight">
                    <a aria-label="Chat on WhatsApp" :href="href+ 'are you up?, lets chat'">Enquiry Now</a>  
                </button>
            </form>
        </div>
    </section>
    
    
    <section id="stats" class="py-16 bg-gray-100">
        <div class="container px-3 mx-auto">
            <h2 class="mb-6 text-4xl font-bold text-center ">
                Freelancing Services
            </h2>
            <p class="max-w-xs mx-auto text-xl text-center text-gray-400 md:max-w-md">
                we provide high quality technology services in affordable price
            </p>
        </div>
    </section>



    <!-- feature box section -->
    <section class="pb-32 bg-gray-100" id="features">
        <div class="container relative flex flex-col items-start px-6 mx-auto md:flex-row md:space-x-7">
            <!-- horizontal line -->
            <div class="absolute hidden w-10/12 h-3 top-24 left-16 bg-cyan md:block"></div>
            <!-- vertical line -->
            <div class="absolute w-2 h-full -ml-1 bg-cyan md:hidden left-1/2"></div>

            <!-- box 1 -->
            <div class="relative flex flex-col p-6 space-y-6 bg-white rounded-lg md:w-1/3">
                <!-- image positioning -->
                <div class="absolute -ml-10 -top-10 left-1/2 md:left-16">
                    <!-- image container for bg and center -->
                    <div class="flex items-center justify-center w-20 h-20 p-4 rounded-full bg-veryDarkViolet">
                        <img src="../assets/images/icon-brand-recognition.svg" alt="">
                    </div>
                </div>
                <h5 class="pt-6 text-xl font-bold text-center capitalize md:text-left">
                    System Development
                </h5>
                <p class="text-center text-gray-400 md:text-left">
                    Managment system, production control system, security systems, information system, communication system and more
                </p>
            </div>

            <!-- box 2 -->
            <div class="relative flex flex-col p-6 mt-24 space-y-6 bg-white rounded-lg md:w-1/3 md:mt-0">
                <!-- image positioning -->
                <div class="absolute -ml-10 -top-10 left-1/2 md:left-16">
                    <!-- image container for bg and center -->
                    <div class="flex items-center justify-center w-20 h-20 p-4 rounded-full bg-veryDarkViolet">
                        <img src="../assets/images/icon-detailed-records.svg" alt="">
                    </div>
                </div>
                <h5 class="pt-6 text-xl font-bold text-center capitalize md:text-left">
                    Websites
                </h5>
                <p class="text-center text-gray-400 md:text-left">
                    information website, personal website, e commerce website, company website, selling website, services website and more
                </p>
            </div>

            <!-- box 3 -->
            <div class="relative flex flex-col p-6 mt-24 space-y-6 bg-white rounded-lg md:w-1/3 md:mt-0">
                <!-- image positioning -->
                <div class="absolute -ml-10 -top-10 left-1/2 md:left-16">
                    <!-- image container for bg and center -->
                    <div class="flex items-center justify-center w-20 h-20 p-4 rounded-full bg-veryDarkViolet">
                        <img src="../assets/images/icon-detailed-records.svg" alt="">
                    </div>
                </div>
                <h5 class="pt-6 text-xl font-bold text-center capitalize md:text-left">
                    Mobile Applications
                </h5>
                <p class="text-center text-gray-400 md:text-left">
                    we develop robust and rich of features mobile app for both android and IOS
                </p>
            </div>

            <!-- box 4 -->
            <div class="relative flex flex-col p-6 mt-24 space-y-6 bg-white rounded-lg md:w-1/3 md:mt-0">
                <!-- image positioning -->
                <div class="absolute -ml-10 -top-10 left-1/2 md:left-16">
                    <!-- image container for bg and center -->
                    <div class="flex items-center justify-center w-20 h-20 p-4 rounded-full bg-veryDarkViolet">
                        <img src="../assets/images/icon-fully-customizable.svg" alt="">
                    </div>
                </div>
                <h5 class="pt-6 text-xl font-bold text-center capitalize md:text-left">
                    IT solution consultant
                </h5>
                <p class="text-center text-gray-400 md:text-left">
                    We give feasible technology solution for any production and social problem. We have large team of different tech expertise to solve real world problems</p>
            </div>

            

        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                href: "https://wa.me/255767488809?text=",
            }
        }
    }
</script>

<style>

</style>

<template>
    <div class="max-w-2xl px-4 pt-6 mb-8 md:mx-auto lg:max-w-7xl lg:px-8">
        <div class="grid grid-cols-2 mt-6 gap-x-6 gap-y-3 lg:grid-cols-4 xl:gap-x-8">
            <div
                class=""
                v-for="product in products"
                :key="product.id"
                >
                <router-link :to="{name: 'product', params: { id : product.id }}" class="">
                    <Product :name="product.name" :img="product.img[0]" :price="product.cost[0]"></Product>
                </router-link>
            </div>
        </div>
    </div>
  
</template>

<script>
import HeroSection from '@/components/HeroSection.vue'
import Products from '@/components/HomePageProducts.vue'
import Product from '@/components/Product.vue'


export default {
props:['products', 'category', 'nav'],
components: { Products, HeroSection, Product },
computed: {
    products: function(){
        return this.products.filter(product => product.category[0] == this.category);
    }
}


}
</script>

<style>

</style>
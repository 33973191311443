<template>
  <div
    class="w-full h-40 overflow-hidden bg-gray-200 rounded-md aspect-h-1 aspect-w-1 lg:aspect-none group-hover:opacity-75 lg:h-80"
  >
    
      <img
        :src="img"
        alt=""
        class="object-cover object-center w-full h-40 lg:h-full lg:w-full"
      />

    
  </div>
  <div class="flex flex-col p-1 space-y-3">
    <!-- name and rates -->
    <div class="flex flex-row items-start justify-between mt-4">
      <div class="text-sm text-gray-700">
        <a href="" class="font-semibold">
          {{ name }}
        </a>
        <div class="flex items-center">
          <div class="mt-1 text-xs text-gray-500">silconi</div>
          <!-- <div class="text-xs text-gray-500 ms-4">sold 300+</div> -->
        </div>
      </div>
      <!-- customer ratings -->
      <div class="flex items-center justify-center ms-2">
        <!-- product ratings -->
        <img src="../assets/images/heart.png" alt="" class="w-4 h-4" />
        <p class="text-sm">5.0</p>
      </div>
    </div>
    <div class="font-semibold text-gray-900 text-md">
      Tsh {{ price }}
    </div>
  </div>
</template>

<script>
export default {
    props: [
        'img',
        'name',
        'price',
        
    ]
};
</script>

<style>
</style>
<template>
    <section id="hero">
      <div class="grid items-start justify-center grid-cols-2 gap-2 p-4 md:grid-cols-8 md:px-6">
        <router-link :to="{ name:'productsCategory', params: {category: 'laptops'}}" class="p-2 border rounded-lg border-primary border-opacity-40 hover:bg-primary hover:text-white hover:text-bold">
 
            <!-- <img src="../assets/images/laptop.svg" alt="" class="w-auto h-16"> -->
              <div class="text-xs font-light text-center ">Laptops</div>
         
        </router-link>
        <router-link :to="{ name:'productsCategory', params: {category: 'accesories'}}" class="p-2 border rounded-lg border-opacity-40 border-primary hover:bg-primary hover:text-white hover:text-bold">
          <!-- <img src="../assets/images/laptopCode.svg" alt="" class="w-auto h-16"> -->
          <div class="text-xs font-light text-center ">Accesories</div>
        </router-link>
        <router-link :to="{ name:'productsCategory', params: {category: 'gadgets'}}" class="p-2 border rounded-lg border-opacity-40 border-primary hover:bg-primary hover:text-white hover:text-bold">
            <!-- <img src="../assets/images/laptopCode.svg" alt="" class="w-auto h-16"> -->
            <div class="text-xs font-light text-center ">Gadgets</div>
        </router-link>
        <router-link :to="{ name:'productsCategory', params: {category: 'spares'}}" class="p-2 border rounded-lg border-opacity-40 border-primary hover:bg-primary hover:text-white hover:text-bold">
            <!-- <img src="../assets/images/laptopCode.svg" alt="" class="w-auto h-16"> -->
            <div class="text-xs font-light text-center ">Computer Spares</div>
        </router-link>
        <router-link to="/about" class="p-2 border rounded-lg border-primary border-opacity-40 hover:bg-primary hover:text-white hover:text-bold">
          <!-- <img src="../assets/images/systems.svg" alt="" class="w-auto h-16"> -->
          <div class="text-xs font-light text-center ">Systems</div>
      </router-link>
      <router-link to="/about" class="p-2 border rounded-lg border-opacity-40 border-primary hover:bg-primary hover:text-white hover:text-bold">
          <!-- <img src="../assets/images/websites.svg" alt="" class="w-auto h-16"> -->
          <div class="text-xs font-light text-center ">Websites</div>
      </router-link>
      <router-link to="/about" class="p-2 border rounded-lg border-opacity-40 border-primary hover:bg-primary hover:text-white hover:text-bold">
        <!-- <img src="../assets/images/mobile.svg" alt="" class="w-auto h-16"> -->
        <div class="text-xs font-light text-center ">Mobile Applications</div>
      </router-link>
      <router-link to="/about" class="p-2 border rounded-lg border-opacity-40 border-primary hover:bg-primary hover:text-white hover:text-bold">
          <!-- <img src="../assets/images/solutions.svg" alt="" class="w-auto h-16"> -->
          <div class="text-xs font-light text-center ">IT Consultant Solution</div>
      </router-link>
      </div>

      
    </section>
</template>

<script>
export default {
    
}
</script>

<style>

</style>
<template class="bg-white font-poppins">
  <HeaderApp @showNav="nav = !nav"></HeaderApp>
  <HeroSection v-show='nav'></HeroSection>
  <div class="border-t" v-show="!nav"></div>
  <router-view :products="products" :nav="nav"></router-view>
  <Footer></Footer>
</template>

<script>
import HeaderApp from './components/HeaderApp.vue';
import HeroSection from './components/HeroSection.vue';
import Products from './components/HomePageProducts.vue';
import FreelancingHome from './components/FreelancingHome.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    HeaderApp,
    HeroSection,
    Products,
    FreelancingHome,
    Footer
  },
  data(){
    return {
      nav: true,
      products : [
        {name:"Dell 3190 x360 touchscreen🔥", specs: "intel celeron/pentium fast max 2.4GHZ,11.6 inch intel UHD graphics 600, RAM 4GB 2400MHZ DDR4, 128GB SSD m2(upgradable),windows 10 3hrs battery time",options:[], cost:[360000], img:[require('@/assets/images/delllatitude3190y1.jpeg'),require('@/assets/images/delllatitude3190y2.jpg'),require('@/assets/images/delllatitude3190y3.jpg'),require('@/assets/images/delllatitude3190y4.jpg')],category: ["laptops"], id:1, tag: ["popular"]},
        {name:"hp probook x360 11 G3 EE Notebook Pc touchscreen",specs: "N4000 celeron/pentium, 128gb ssd(upgradable), 4gb RAM, Wi-fi, windows 10 pro", cost:[350000], options:[],img:[require("@/assets/images/hpprobook11ey1.jpg"),require("@/assets/images/hpprobook11ey2.jpg"),require("@/assets/images/hpprobook11ey3.jpg"),require("@/assets/images/hpprobook11ey4.jpg"),],category: ["laptops"],  id:16, tag:[]},
        {name:"Macbook Pro 2022 m2 brand new with apple warranty", specs:"13.3-inch (diagonal) LED-backlit display with IPS technology; 2560-by-1600 native resolution at 227 pixels per inch with support for millions of colors, Up to 20 hours Apple TV app movie playback, 65 (U.S.) or 66 (ISO) keys including 4 arrow keys in an inverted‑T arrangement, Touch Bar touch ID, 8-core CPU with 4 performance cores and 4 efficiency cores, 10-core GPU, 16-core Neural Engine, 100GB/s memory bandwidth",options:["SSD 256 GB M2", "SSD 512 GB M2"], cost:[3400000, 3850000], img:[require("@/assets/images/mackbookpro2022.png")],category: ["laptops"], id:2, tag:["hot"]},
        {name:"Thinkpad 11e Yoga gen 6 x360 touchscreen", specs:'8 gen intel core m3-81000Y 1.1 to 3.4 GHZ turbo boost 4MB cache,11.6" intel&reg; UHD Graphics 615 touchscreen,8GB RAM 1866MHZ, 256GB PCIe SSD, Windows 10 pro 5hrs battery',options:[], cost:[497600], img:[require("@/assets/images/thinkpad11eyogay1.jpg"),require("@/assets/images/thinkpad11eyogay2.jpg"),require("@/assets/images/thinkpad11eyogay3.jpg"),require("@/assets/images/thinkpad11eyogay4.jpg"),require("@/assets/images/thinkpad11eyogay5.jpg"),],category: ["laptops"],id:3, tag:[]},
        {name:"Hp EliteBook 820 G3 notebook PC", specs:"core i5-6300U(6 gen) 2.8GHZ 3MB cache,12.5inch intel HD graphics 520, HDD 500GB 7200rpm, 8GB RAM DDR4, windows 11 4 hrs battery life ",options:[], cost:[450000], img:[require("@/assets/images/hp820g3y1.jpg"),require("@/assets/images/hp820g3y2.jpg"),require("@/assets/images/hp820g3y3.jpg"),require("@/assets/images/hp820g3y1.jpg")],category: ["laptops"], id:4,tag:["popular"]},
        {name:"hp EliteBook x2 1012 G2 tablet touchscreen", specs:"intel core i5-7100U 2.4GHZ 3MB cache, 12.3inch better than QHD intel HD 620, 256GB SSD M2 and SATA, 8GB DDR 3, windows 11 pro  ",options:[], cost:[655000], img:[require("@/assets/images/EliteBookx21012.png")],category: ["laptops"], id:5,tag:[]},
        {name:"hp EliteBook x2 1012 G2 tablet touchscreen", specs:"intel core i7-7100U 2.9GHZ 8MB cache, 12.3inch better than QHD intel HD 620, 256GB SSD M2 and SATA, 8GB DDR 3, windows 11 pro  ",options:[], cost:[690000], img:[require("@/assets/images/hpx2y1.jpg"),require("@/assets/images/hpx2y2.jpg"),require("@/assets/images/hpx2y3.jpg")],category: ["laptops"], id:6, tag:[]},
        {name:"hp ElteBook 840 G5 tuchscreen", specs: "core i5-7gen 2.5GHZ 6MB cache, 14inch intel UHD 620, 256GB SSD PCIe NVme, 8GB RAM DDR4 2400MHZ, windows 11 pro pes 23", cost:[700000],options:[], img:[require("@/assets/images/hp840g5.jpeg"),require("@/assets/images/hp840g5y2.jpeg"),require("@/assets/images/hp840g5y3.jpeg"),require("@/assets/images/hp840g5y4.jpeg"),require("@/assets/images/hp840g5y5.jpeg"),require("@/assets/images/hp840g5y6.jpeg")],category: ["laptops"], id:7, tag:["popular"]},
        {name:"harddisk sata 2.5 320GB, 500GB, 1TB",specs:"fast file transfer, low heat, less noise", options:['320GB', '500GB 7200rpm', '1TB'],cost:[30000,45000,90000],img:[require("@/assets/images/hddy1.jpeg")],category:["accesories"], id:8, tag:["hot"]},
        {name:"External Harddisk case",specs:"protect hard disk from shocks, keep hard disk health in long time", options:["1-10,000","2-14,000","3-20,000"], cost:[10000,14000, 200000],img:[require("@/assets/images/ssdm2256.jpeg")],category:["accesories"], id:9, tag:[]},
        {name:"RAM 2GB 8GB 16GB DDR3 DDR4", specs:"boost your computer speed and perfomance", options:['2GB-DDR3', '4GB-DDR3', '8GB-DDR3', '4GB-DDR4','8GB-DDR4','16GB-DDR4'], cost:[25000,35000,65000,55000, 70000,120000],img:[require("@/assets/images/ramy2.jpeg")],category:["accesories"], id:10, tag:[]},
        {name:"Laptops Adapter",specs:"power you laptop in short time and durable perfect replacement of pc adapter",options:["adapter", " Pro adapter"],cost:[25000,30000],img:[require("@/assets/images/laptopsadaptery1.jpeg")],category:["computer spare"], id:11, tag:[]},
        {name:"Earpods",specs:"Quality music",options:[],cost:[10000],img:[require("@/assets/images/podsc.jpeg"),require("@/assets/images/earpods85y2.jpeg")],category:["gadgets"], id:12,tag:[]},
        {name:"Earpods",specs:"Quality music",options:[],cost:[15000],img:[require("@/assets/images/earpods85y1.jpeg"),require("@/assets/images/earpods85y2.jpeg")],category:["gadgets"], id:16,tag:[]},
        {name:"apple airpods pro 2nd gen, 1st gen",specs:"good music, noise cancellation ",options:["1st gen", "2nd gen"],cost:[30000, 35000],img:[require("@/assets/images/earpodspro.jpeg" ), require("@/assets/images/appleairpods1.jpeg"), require("@/assets/images/appleairpods2.jpeg")],category:["gadgets"], id:14, tag:["hot"]},
        {name:"airpods pro",specs:"good music, noise cancellation ",options:[],cost:[45000],img:[require("@/assets/images/earpodspro.jpeg")],category:[], id:15, tag:[]},
        {name:"Scientific calculator f991",specs:"All mathematics scientitific computations",options:["1", "2", "3"],cost:[18000, 25000,30000],img:[require("@/assets/images/calculator1.jpeg"),require("@/assets/images/calculator2.jpeg"),require("@/assets/images/calculator3.jpeg")],category:["gadgets"], id:17,tag:["popular"]},
        {name:"Minkeyboard",specs:"portable and high quality keys",options:["keyboard", "keyboard + wireless mouse"],cost:[13000, 18000],img:[require("@/assets/images/minkeyboard.jpeg")],category:["accesories"], id:18,tag:[]},
        {name:"Game pad for pc, console(PS, XBox)",specs:"Game pad for consoles",options:["Sony pad for Ps3", "PS4 pad","dual shock 4 (PS5 pad)", "Sony PS5 dual shock 5", "microsoft XBox game pad"],cost:[20000, 45000, 70000, 200000, 230000],img:[require("@/assets/images/pad.jpeg")],category:["gadgets"], id:19,tag:["hot"]},
        {name:"Bill counting machine",specs:"al currency accurate counting machine",options:["small"],cost:[270000, 350000],img:[require("@/assets/images/billcounter.jpeg"), require("@/assets/images/billcounter2.jpeg")],category:["gadgets"], id:20, tag:[]},
        {name:"Samsung adapter c-c Original 45W",specs:"supports super faster charging ",options:[],cost:[15000],img:[require("@/assets/images/samsungadaptercc.jpeg")],category:["accesories"], id:21, tag:[]},
        {name:"Samsung earpods",specs:"High quality music from samsung pods by AKG",options:[],cost:[35000],img:[require("@/assets/images/samsungbuds.jpeg"), require("@/assets/images/samsungbuds2.jpeg")],category:[], id:22, tag:[]},
        {name:"Smart watches from 20,0000",specs:"repply call or message via watch, track heartbeat and physical activities",options:[],cost:[20000],img:[require("@/assets/images/smartwatche.jpeg"), require("@/assets/images/smartwatchc1.jpeg"),require("@/assets/images/smartwachc1.jpeg")],category:["gadgets"], id:23, tag:[]},

      ]
    }
  },
  methods:{
    
  }
}
</script>

<style>

</style>

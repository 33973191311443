import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import SingleProductPage from '@/views/SingleProductPage'
import CheckoutForm from '@/views/CheckoutForm'
import Products from '@/views/Products.vue'
import About from '@/views/About.vue'

const routes = [
  {
    path : '/',
    name: 'home',
    component: Home
  },
  {
    path: '/product/:id',
    name : 'product',
    component : SingleProductPage,
    props: true
  },
  {
    path: '/checkout/:id',
    name: 'checkout',
    component : CheckoutForm,
    props: true
  },
  {
    path: '/products/:category',
    name: 'productsCategory',
    component : Products,
    props: true
  },
  {
    path: '/about',
    name: 'About',
    component: About
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <footer class="py-16 bg-black">
        <div class="container flex flex-col items-center justify-between mx-auto space-y-16 lex md:flex-row md:space-y-0 md:items-start">
             <!-- logo -->
             <!-- menu container -->
             <div class="flex flex-col space-y-16 md:space-x-20 md:flex-row md:space-y-0">
                <!-- menu-1 -->
                <!-- <div class="flex flex-col items-center w-full md:items-start">
                    <div class="mb-5 font-bold text-white capilize">
                         Resources
                    </div>
                    <div class="flex flex-col items-center space-y-3 md:items-start">
                        <a href="" class="capitalize text-grayishViolet hover:text-cyan">
                            Blogs
                        </a>
                        <a href="" class="capitalize text-grayishViolet hover:text-cyan">
                            Developers
                        </a>
                        <a href="" class="capitalize text-grayishViolet hover:text-cyan">
                            Support
                        </a>
                    </div>
                </div> -->

                    <!-- menu-2 -->
                <div class="flex flex-col items-center w-full md:items-start">
                    <div class="mb-5 font-bold text-white capilize">
                        Services
                    </div>
                    <div class="flex flex-col items-center space-y-3 md:items-start">
                        <a href="" class="capitalize text-grayishViolet hover:text-cyan">
                            Electronics
                        </a>
                        <a href="" class="capitalize text-grayishViolet hover:text-cyan">
                            Freelancing
                        </a>
                        <a href="" class="capitalize text-grayishViolet hover:text-cyan">
                            It consultant
                        </a>
                    </div>
                </div>


                <!-- menu 3 -->
                <div class="flex flex-col items-center w-full md:items-start">
                    <div class="mb-5 font-bold text-white capilize">
                        Company
                    </div>
                    <div class="flex flex-col items-center space-y-3 md:items-start">
                        <router-link to="/about" class="capitalize text-grayishViolet hover:text-cyan" replace>
                            About
                        </router-link>
                        <router-link to="/about" class="capitalize text-grayishViolet hover:text-cyan" replace>
                            Our team
                        </router-link>
                        <router-link to="/about" class="capitalize text-grayishViolet hover:text-cyan" replace>
                        Careers
                        </router-link>
                        <router-link to="/about" class="capitalize text-grayishViolet hover:text-cyan" replace>
                        Contact
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="flex space-x-6">
                <a href="#">
                    <img src="../assets/images/icon-facebook.svg" alt="" class="ficon">
                </a>
                <a href="#">
                    <img src="../assets/images/icon-twitter.svg" alt="" class="ficon">
                </a>
                <a href="#">
                    <img src="../assets/images/icon-pinterest.svg" alt="" class="ficon">
                </a>
                <a href="#">
                    <img src="../assets/images/icon-instagram.svg" alt="" class="ficon">
                </a>
            </div>

            <h2 class="font-bold tracking-wider text-white text-md">SILIKAZI@2024</h2>
  
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>
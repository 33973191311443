<template>
  <div class="flex flex-col items-center py-4 bg-white border-t sm:flex-row sm:px-10 lg:px-20 xl:px-32">
    <!-- <a href="#" class="text-2xl font-bold text-gray-800">silikazi Checkout</a> -->
    <div class="py-2 mt-4 text-xs sm:mt-0 sm:ml-auto sm:text-base ms-2">
      <div class="relative">
        <ul class="relative flex items-center justify-between w-full space-x-2 sm:space-x-4">
          <li class="flex items-center space-x-3 text-left sm:space-x-4">
            <a class="flex items-center justify-center w-6 h-6 text-xs font-semibold text-white rounded-full bg-primary" href="#"
              ><svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" /></svg
            ></a>
            <span class="font-semibold text-gray-900">Shop</span>
          </li>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
          </svg>
          <li class="flex items-center space-x-3 text-left sm:space-x-4">
            <a class="flex items-center justify-center w-6 h-6 text-xs font-semibold text-white bg-gray-600 rounded-full ring ring-gray-600 ring-offset-2" href="#">2</a>
            <span class="font-semibold text-gray-900">Checkout</span>
          </li>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
          </svg>
          <li class="flex items-center space-x-3 text-left sm:space-x-4">
            <a class="flex items-center justify-center w-6 h-6 text-xs font-semibold text-white bg-gray-400 rounded-full" href="#">3</a>
            <span class="font-semibold text-gray-500">Track your product</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
    <div class="px-4 pt-8">
      <p class="text-xl font-medium">Order Summary</p>
      <p class="text-gray-400">Check your items. And select a suitable shipping method.</p>
      <div class="px-2 py-4 mt-8 space-y-3 bg-white border rounded-lg sm:px-6">
        <div class="flex flex-col bg-white rounded-lg sm:flex-row">
          <img class="object-cover object-center h-24 m-2 border rounded-md w-28" :src="product.img[0]" alt="" />
          <div class="flex flex-col w-full px-4 py-4">
            <span class="font-semibold">{{ product.name }}</span>
            <span class="float-right text-blue-400">Brand new</span>
            <p class="text-lg font-bold">{{ product.cost[costIndex] }} </p>
          </div>
        </div>

      </div>
  
      <p class="mt-8 text-lg font-medium">Shipping Methods</p>
      <form class="grid gap-6 mt-5">
        <div class="relative">
          <input class="hidden peer" id="radio_1" type="radio" name="radio" checked v-model="addressDelivery"/>
          <span class="box-content absolute block w-3 h-3 -translate-y-1/2 bg-white border-8 border-gray-300 rounded-full peer-checked:border-blue-600 right-4 top-1/2"></span>
          <label class="flex p-4 border border-gray-300 rounded-lg cursor-pointer select-none peer-checked:border-2 peer-checked:border-blue-600 peer-checked:bg-blue-50" for="radio_1">
            <img src="../assets/images/addressDelivery.svg" alt="" class="w-24 h-20">
            <div class="ml-5">
              <span class="mt-2 font-semibold">Address delivery</span>
              <p class="text-sm leading-6 text-slate-500">Delivery: 1 hour</p>
            </div>
          </label>
        </div>
        <div class="relative items-center justify-center">
          <input class="hidden peer" id="radio_2" type="radio" name="radio" checked />
          <span class="box-content absolute block w-3 h-3 -translate-y-1/2 bg-white border-8 border-gray-300 rounded-full peer-checked:border-blue-700 right-4 top-1/2"></span>
          <label class="flex p-4 border border-gray-300 rounded-lg cursor-pointer select-none peer-checked:border-2 peer-checked:border-blue-700 peer-checked:bg-blue-50" for="radio_2">
          <img src="../assets/images/storePick.svg" alt="" class="w-24 h-20">
            <div class="ml-5">
              <span class="mt-2 font-semibold">Store Pickup</span>
              <p class="text-sm leading-6 text-slate-500">Delivery: ....</p>
            </div>
          </label>
        </div>
      </form>
    </div>
    <div class="px-4 pt-8 mt-10 bg-gray-50 lg:mt-0 mb-44">
      <p class="text-xl font-medium">Payment Details</p>
      <p class="text-gray-400">Complete your order by providing your payment details.</p>
      <div class="">
        <label for="email" class="block mt-4 mb-2 text-sm font-medium">Name</label>
        <div class="relative">
          <input type="text" id="email" name="name" class="w-full px-4 py-3 text-sm border border-gray-200 rounded-md shadow-sm outline-none pl-11 focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="i.e shad mbwagha" v-model='name'/>
          <div class="absolute inset-y-0 left-0 inline-flex items-center px-3 pointer-events-none">
            <img src="../assets/images/user.svg" alt="" class="w-4 h-4 text-blue-400">
          </div>
        </div>
        <label for="card-holder" class="block mt-4 mb-2 text-sm font-medium">Receiver Phone Number</label>
        <div class="relative">
          <input type="number" id="phone" name="phone number" class="w-full px-4 py-3 text-sm uppercase border border-gray-200 rounded-md shadow-sm outline-none pl-11 focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="receiver phone number" v-model="phoneNumber"/>
          <div class="absolute inset-y-0 left-0 inline-flex items-center px-3 pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
            </svg>
          </div>
        </div>
        <!-- <label for="card-no" class="block mt-4 mb-2 text-sm font-medium">Card Details</label>
        <div class="flex">
          <div class="relative flex-shrink-0 w-7/12">
            <input type="text" id="card-no" name="card-no" class="w-full px-2 py-3 text-sm border border-gray-200 rounded-md shadow-sm outline-none pl-11 focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="xxxx-xxxx-xxxx-xxxx" disabled/>
            <div class="absolute inset-y-0 left-0 inline-flex items-center px-3 pointer-events-none">
              <svg class="w-4 h-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
              </svg>
            </div>
          </div>
          <input type="text" name="credit-expiry" class="w-full px-2 py-3 text-sm border border-gray-200 rounded-md shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="MM/YY" />
          <input type="text" name="credit-cvc" class="flex-shrink-0 w-1/6 px-2 py-3 text-sm border border-gray-200 rounded-md shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="CVC" />
        </div> -->
        <label for="billing-address" class="block mt-4 mb-2 text-sm font-medium">Billing Address</label>
        <div class="flex flex-col space-y-3 sm:flex-row">
          <div class="relative flex-shrink-0 sm:w-7/12">
            <input type="text" id="billing-address" name="billing-address" class="w-full px-4 py-3 text-sm border border-gray-200 rounded-md shadow-sm outline-none pl-11 focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="region" v-model="address1" />
            <div class="absolute inset-y-0 left-0 inline-flex items-center px-3 pointer-events-none">
              <img class="object-contain w-4 h-4" src="../assets/images/tanzania.svg" alt="" />
            </div>
          </div>
          <!-- <select type="text" name="billing-state" class="w-full px-4 py-3 text-sm border border-gray-200 rounded-md shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500">
            <option value="State">State</option>
          </select> -->
          <input type="text" name="billing address" class="flex-shrink-0 px-4 py-3 text-sm border border-gray-200 rounded-md shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="district ward street" v-model="address2"/>
          <input type="text" name="billing-zip" class="flex-shrink-0 px-4 py-3 text-sm border border-gray-200 rounded-md shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="ZIP(optional)" v-model="zip" />
        </div>
  
        <!-- Total -->
        <div class="py-2 mt-6 border-t border-b">
          <div class="flex items-center justify-between">
            <p class="text-sm font-medium text-gray-900">Subtotal</p>
            <p class="font-semibold text-gray-900">Tsh {{ product.cost[costIndex] }}</p>
          </div>
          <div class="flex items-center justify-between">
            <p class="text-sm font-medium text-gray-900">Shipping</p>
            <p class="font-semibold text-gray-900">Tsh 0</p>
          </div>
        </div>
        <div class="flex items-center justify-between mt-6">
          <p class="text-sm font-medium text-gray-900">Total</p>
          <p class="text-2xl font-semibold text-gray-900">Tsh {{ product.cost[costIndex] }}</p>
        </div>
      </div>
      <button class="w-full px-6 py-3 mt-4 mb-8 font-medium text-white bg-blue-600 rounded-md"><a aria-label="Chat on WhatsApp" :href="href +'Order details%0aName: '+ name +'%0aProduct: '+  product.name + '%0aprice: '+product.cost[costIndex]+'%0aPhone number: '+ phoneNumber + '%0aAddress: '+ address1+' , '+ address2">i'm ready to pay? Place Order</a></button>
    </div>
  </div>
  
</template>

<script>
export default {
  props: ['id', 'products'],
  data(){
    return {
      costIndex: this.$route.query.costIndex,
      href: "https://wa.me/255767488809?text=",
      addressDelivery: '',
      name: '',
      phoneNumber: '',
      address1: '',
      address2: '',
      zip: ''
      
    }
  },
  computed: {
    product(){
      const itemId = this.id;
      return this.products.find((product) => product.id == itemId);
    }
  }
};
</script>

<style>
</style>
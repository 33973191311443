<template>
    <!-- Header -->
    <header class="bg-white">
      <nav class="flex items-center justify-between p-6 max-w-7xl lg:px-8">
        <div class="z">
          <a href="/" class="-m-1.5 p-1.5 font-bold">
            <span class="uppercase sr-only text-primary">silikazi</span>
            <span class="">SILIKAZI</span>
          </a>
        </div>

        <div class="flex lg:hidden" @click="showNav">
          <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
            <span class="sr-only">Open main menu</span>
            <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>


        <div class="hidden lg:flex lg:gap-x-12">
          <a href="" class="text-sm font-semibold leading-6 text-gray-900">Electronics</a>
          <a href="" class="text-sm font-semibold leading-6 text-gray-900">Freelancing</a>
          <router-link to='/about' class="text-sm font-semibold leading-6 text-gray-900" replace>About Us</router-link>
        </div>       
      </nav>

      

    

      
      <!-- <div class="flex flex-col items-center justify-between pt-4 md:flex-row md:px-4 md:pt-2">
        <div class="text-2xl font-bold tracking-wide text-center text-primary md:text-left">SILIKAZI</div>
        <div class="flex flex-row justify-center p-6 space-x-10 md:space-x-6 md:justify-around">
          <div class="text-sm font-bold">Home</div>
          <div class="text-sm font-bold">Electronics</div>
          <div class="text-sm font-bold">Freelancing</div>
          <div class="text-sm font-bold">About us</div>
        </div>
      </div> -->
    </header>
</template>

<script>
export default {
  data(){
    return {
      nav: true
    }
  },
  methods: {
    showNav(){
      this.$emit('showNav', this.nav);
    }
  }
}
</script>

<style>

</style>
<template>
  <section class="pt-8 pb-20 font-poppins">
    <div class="max-w-6xl px-4 mx-auto">
      <div class="flex flex-wrap mb-24 -mx-4">
        <div class="w-full px-4 mb-8 md:w-1/2 md:mb-0">
          <div class="sticky top-0 z-50 overflow-hidden">
            <div class="relative mb-6 lg:mb-10">
              <a
                class="absolute left-0 transform lg:ml-2 top-1/2 translate-1/2"
                href="#"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="w-5 h-5 text-blue-500 bi bi-chevron-left "
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                  ></path>
                </svg>
              </a>
              <img
                class="object-cover w-auto lg:h-1/2"
                :src="product.img[currentImageIndex]"
                alt=""
              />
              <a
                class="absolute right-0 transform lg:mr-2 top-1/2 translate-1/2"
                href="#"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="w-5 h-5 text-blue-500 bi bi-chevron-right"
                  viewBox="0 0 16 16"
                  @click="incrImageIndex()"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </a>
            </div>
            <div class="flex-wrap hidden -mx-2 md:flex">
              <div v-for="img in product.img" :key="img" class="w-1/2 p-2 sm:w-1/4">
                <a
                  class="block border border-transparent hover:border-blue-400"
                  href="#"
                >
                  <img
                    class="object-cover w-full lg:h-auto"
                    :src="img"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div
              class="px-6 pb-6 mt-6 border-t border-gray-300 "
            >
              <div class="flex items-center justify-center mt-6">
                <span class="mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="w-5 h-5 text-blue-700 bi bi-chat-left-dots-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm5 4a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                    ></path>
                  </svg>
                </span>
                <div>
                  <h2
                    class="text-sm font-bold text-gray-700 lg:text-lg "
                  >
                    Have question about buying an this product
                  </h2>
                  <a
                    class="text-xs text-blue-400 lg:text-sm"
                    :href="'https://wa.me/255767488809?text=i want to know more about '+ product.name"
                  >
                    Chat with an {{ product.name }} specialist
                  </a>
                </div>  
              </div>
            </div>
          </div>
        </div>
        <div class="w-full px-4 md:w-1/2">
          <div class="lg:pl-20">
            <div class="mb-6">
              <span class="text-red-500 ">New</span>
              <h2
                class="max-w-xl mt-2 mb-4 text-5xl font-bold md:text-6xl font-heading"
              >
                Buy {{ product.name }}
              </h2>
              <p class="max-w-md mb-4 text-gray-500">
                buy more products from us to get whole sale prices
              </p>
              <a
                :href=" href + ' how can i trust you to get my product as my order?' "
                class="text-blue-500 hover:underline "
              >
                See how trade-in works</a
              >
            </div>
            <div class="" v-if="product.specs.length > 0">
              <p class="mb-4 text-lg font-semibold ">
                Product Specs
              </p>
              <div
                class="grid grid-cols-1 gap-4 pb-4 border-b-2 border-gray-300 "
              >
                <div v-for="productSpec in productSpecs" :key="productSpec">
                  <div
                    class="flex items-center w-full h-full px-2 py-2 hover:border-blue-400"
                  >
                    <div>
                   
                      <li
                        class="text-sm text-gray-700 "
                      >
                        {{ productSpec }}
                      </li>
                    </div>
                  </div>
                </div>
                <!-- <div>
                  <button
                    class="flex items-center justify-center w-full h-full py-4 border-2 border-gray-300 hover:border-blue-400"
                  >
                    <div>
                      <div
                        class="w-8 h-8 mx-auto mb-2 bg-gray-700 rounded-full "
                      ></div>
                      <p
                        class="text-xs text-center text-gray-700 "
                      >
                        Mattee Black
                      </p>
                    </div>
                  </button>
                </div> -->
                

              </div>
            </div>
             
            <div class="mt-6" v-if="product.options.length > 0">
              <p class="mb-2 text-lg font-semibold ">
                product options
              </p>
              <a
                href="#"
                class="text-blue-500 hover:underline "
              >
                How much capacity is right for you?</a
              >
              <div
                class="grid grid-cols-2 gap-4 pb-4 mt-2 mb-4 border-b-2 border-gray-300 lg:grid-cols-3 "
              > 
                <div v-for='option,index in product.options' :key="option" @click="costIndex = index">
                  <button
                    class="flex items-center justify-center w-full h-full py-4 border-2 border-gray-300 hover:border-blue-400"
                  >
                    <div>
                      <div class="mb-2 font-semibold ">
                        {{ option }}<span class="text-xs"></span>
                      </div>
                      <p
                        class="px-2 text-xs font-medium text-center text-gray-700 "
                      >
                        Tsh {{ product.cost[index]}}
                      </p>
                    </div>
                  </button>
                </div>
                <!-- <div>
                  <button
                    class="flex items-center justify-center w-full h-full py-4 border-2 border-gray-300 hover:border-blue-400"
                  >
                    <div>
                      <div class="mb-2 font-semibold ">
                        256 <span class="text-xs">GB</span>
                      </div>
                      <p
                        class="px-2 text-xs font-medium text-center text-gray-700 "
                      >
                        From $99 0r $41.62/mo. for 24 mo.
                      </p>
                    </div>
                  </button>
                </div> -->

              </div>
            </div>
            <!-- <div class="mt-6">
              <p class="mb-4 text-lg font-semibold ">
                Choose a payment option
              </p>
              <div
                class="grid grid-cols-2 gap-4 pb-4 mt-2 mb-4 border-b-2 border-gray-300 lg:grid-cols-3 "
              >
                <div>
                  <button
                    class="flex items-center justify-center w-full h-full px-2 py-6 border-2 border-gray-300 hover:border-blue-400"
                  >
                    <div>
                      <p
                        class="px-2 text-base font-semibold text-center text-gray-700 "
                      >
                        Pay in full
                      </p>
                    </div>
                  </button>
                </div>
                <div>
                  <button
                    class="flex items-center justify-center w-full h-full px-2 py-6 border-2 border-gray-300 hover:border-blue-400"
                  >
                    <div>
                      <p
                        class="px-2 text-base font-semibold text-center text-gray-700 "
                      >
                        Pay monthly
                      </p>
                    </div>
                  </button>
                </div>
              </div>
            </div> -->
            <div class="mt-6">
              <div class="flex flex-wrap items-center">
                <span class="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="w-4 h-4 text-gray-700 bi bi-truck"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                    ></path>
                  </svg>
                </span>
                <h2 class="text-lg font-bold text-gray-700 ">
                  Delivery
                </h2>
              </div>
              <div class="px-7">
                <p class="mb-2 text-sm">In Stock</p>
                <p class="mb-2 text-sm">Free Shipping</p>
                <a
                  class="mb-2 text-sm text-blue-400"
                  :href="href + 'lets arrange delivery of ' + product.name"
                  >Arrange delivery</a
                >
              </div>

              
            </div>
            <div class="mt-6">
              <div class="flex flex-wrap items-center">
                <span class="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="w-4 h-4 text-gray-700 bi bi-bag"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"
                    ></path>
                  </svg>
                </span>
                <h2 class="text-lg font-bold text-gray-700">
                  Pickup
                </h2>
              </div>
              <div class="px-7">
                <a
                  class="mb-2 text-sm text-blue-400 "
                  href="#"
                  >Check availability</a
                >
              </div>
            </div>
            <p class="mb-4 text-lg font-semibold mt-7">
                Total: {{ product.cost[costIndex] }}
              </p>
            
            <div class="mt-6">
              <router-link :to="{name: 'checkout', params: { id: product.id}, query: { costIndex: costIndex } }">
                <button
                  class="w-full px-4 py-2 font-bold text-white bg-blue-600 lg:w-96 hover:bg-blue-500"
                >
                  Continue
                </button>
              </router-link>
             
            </div>
            <div class="flex items-center mt-6">
              <div>
                <h2
                  class="mb-2 text-lg font-bold text-gray-700 "
                >
                  Still deciding?
                </h2>
                <p class="mb-2 text-sm ">
                  Add this item to a list and easily come back to it later
                </p>
              </div>
              <span class="ml-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="w-6 h-6 text-blue-500 cursor-pointer hover:text-blue-600 "
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"
                  ></path></svg
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['id', 'products'],
  computed: {
  product(){
      const itemId = this.id;
      return this.products.find((product) => product.id == itemId);
    },
    productSpecs(){
      return this.product.specs.split(",");
    },
    total(){
     return this.product.cost[this.costIndex];
    }
    
  },
  data(){
    return {
      currentImageIndex: 0,
      costIndex: 0,
      href: "https://wa.me/255767488809?text=",
    }
    
  },
  methods: {
    incrImageIndex(){
      this.currentImageIndex = this.currentImageIndex+1;
      
      if( this.currentImageIndex >= this.product.img.length)
        this.currentImageIndex = 0;
      
    }
  },
  mounted(){
    
  }

  
  
};

</script>

<style>
</style>
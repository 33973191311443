<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <section class="max-w-2xl mx-auto lg:max-w-7xl lg:px-8">
    <div class="bg-white">
      <div
        class="max-w-2xl px-4 pt-6 mx-auto sm:px-6 sm:pt-10 lg:max-w-7xl lg:px-8"
      >
        <h2 class="text-2xl font-bold tracking-tight text-gray-900">
          top products 🔥
        </h2>

        <div
          class="grid grid-cols-2 mt-6 gap-x-6 gap-y-3 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8"
        >
          <div
            class=""
            v-for="(product) in popular"
            :key="product.id"
          > 
            <div class="relative group">
              <router-link :to="{name: 'product', params: { id: product.id}}" class="">
                <Product :name="product.name" :img="product.img[0]" :price="product.cost[0]"></Product>
              </router-link>
            </div>
            
          
            
          </div>
        </div>

        <h2 class="text-2xl font-bold tracking-tight text-gray-900">
          new arrivals 💫
        </h2>

        <div
          class="grid grid-cols-2 mt-6 mb-24 gap-x-6 gap-y-2 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8"
        >
          <div
            class=""
            v-for="(product) in hot"
            :key="product.id"
          > 
            <div class="relative group">
              <router-link :to="{name: 'product', params: { id: product.id}}" class="">
               <Product :name="product.name" :img="product.img[0]" :price="product.cost[0]"></Product>
              </router-link>
            </div>
          </div>
        </div>
        
      </div>    
    </div>
  </section>
</template>

<script>
import Product from './Product.vue';

export default {
  props: ['products' ],
  components: { Product },
  computed: {
    popular: function(){
        return this.products.filter(product => product.tag[0] == "popular");
    },
    hot: function(){
        return this.products.filter(product => product.tag[0] == "hot");
    }
}
};
</script>

<style>
</style>

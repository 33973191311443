
<template>
  <Products :products="products"></Products>
  <div class="mt-10 md:mt-24">
    <FreelancingHome></FreelancingHome>
  </div>
</template>

<script>
import HeroSection from '@/components/HeroSection.vue';
import Products from '@/components/HomePageProducts.vue';
import FreelancingHome from '@/components/FreelancingHome.vue';
export default {
  props: [ 'products', 'nav'],
  components : {
    HeroSection,
    Products,
    FreelancingHome
  }
}
</script>

<style>

</style>